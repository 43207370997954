
import http from "@/common/http";
import { ApGamePayTypes, getLabelByValue, mallGoodsTypes, ApGamePayStatusList, ApGamePayStatus } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue/es/pagination";
import { SendOutlined } from "@ant-design/icons-vue";

const columns = [
  {
    title: "序号",
    width: 70,
    dataIndex: "index",
    // fixed: "left",
  },

  {
    title: "充值金额",
    width: 100,
    dataIndex: "realAmount",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 100,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "支付状态",
    width: 90,
    dataIndex: "status",
    ellipsis: true,
  },

  {
    title: "订单号",
    width: 180,
    dataIndex: "orderNo",
    ellipsis: true,
  },

  {
    title: "外部订单号",
    width: 180,
    dataIndex: "outTradeNo",
    ellipsis: true,
  },

  {
    title: "物品类型",
    width: 100,
    dataIndex: "mallGoodsType",
    ellipsis: true,
  },

  {
    title: "获得物品",
    width: 160,
    dataIndex: "gainGoodsAmount",
    ellipsis: true,
  },

  {
    title: "支付方式",
    width: 100,
    dataIndex: "payType",
    ellipsis: true,
  },

  {
    title: "userId",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 120,
    dataIndex: "remark",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
  },

  {
    title: "回调时间",
    width: 180,
    dataIndex: "notifyTime",
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    // fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  name: "re_ap_game_pay",
  components: {
    SendOutlined,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        orderNo: "",
        status: ApGamePayStatus.Success,
        maskId: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        payType: undefined,
        mallGoodsType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);
    const totalLoading = ref(true);
    const showManualNotify = ref(false);
    const manualNotifyOrderNo = ref("");
    const manualNotifyTableName = ref("");

    const currPagConfig = JSON.parse(JSON.stringify(pagConfig));
    const pagination = reactive(currPagConfig);

    const data = ref([]);

    //统计数据
    const totalData = reactive({
      totalAmount: 0,
      totalCount: 0,
      totalUserCount: 0,
    });

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("re_ap_game_pay/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("re_ap_game_pay", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //获取合计信息
    const getTotalData = async () => {
      try {
        totalLoading.value = true;
        const result = await http.get("re_ap_game_pay/list_statistics", {
          ...search.relData,
        });

        totalData.totalAmount = result.data.data.totalAmount;
        totalData.totalCount = result.data.data.totalCount;
        totalData.totalUserCount = result.data.data.totalUserCount;

        totalLoading.value = false;
      } catch (e) {
        totalLoading.value = false;
      }
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
      getTotalData();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    //显示手动回调
    const handleNotify = (record: any) => {
      manualNotifyTableName.value = record.tableName;
      manualNotifyOrderNo.value = record.orderNo;

      nextTick(() => {
        showManualNotify.value = true;
      });
    };

    const onNotifySuccess = () => {
      getList();
      getTotalData();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      mallGoodsTypes,
      ApGamePayTypes,
      ApGamePayStatusList,
      totalLoading,
      totalData,
      showManualNotify,
      manualNotifyOrderNo,
      manualNotifyTableName,
      handleTableChange,
      onSearch,
      getList,
      onReset,
      handleNotify,
      searchCol,
      disabledDate,
      getLabelByValue,
      getTotalData,
      onNotifySuccess,
    };
  },
});
