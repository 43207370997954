<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="创建时间" name="startTime">
                  <a-date-picker v-model:value="search.data.startTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="endTime">
                  <a-date-picker v-model:value="search.data.endTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="支付状态" name="status">
              <a-select v-model:value="search.data.status" placeholder="请选择支付状态" allowClear>
                <a-select-option :value="-1">全部</a-select-option>
                <a-select-option :value="item.value" v-for="item in ApGamePayStatusList" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="订单号" name="orderNo">
              <a-input v-model:value.number="search.data.orderNo" placeholder="请输入订单号" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="search.data.maskId" placeholder="请输入游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="物品类型" name="mallGoodsType">
              <a-select v-model:value="search.data.mallGoodsType" placeholder="请选择物品类型" allowClear>
                <a-select-option :value="item.value" v-for="item in mallGoodsTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="支付方式" name="payType">
              <a-select v-model:value="search.data.payType" placeholder="请选择支付方式" allowClear>
                <a-select-option :value="item.value" v-for="item in ApGamePayTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="userId" name="userId">
              <a-input v-model:value.number="search.data.userId" placeholder="请输入userId" allowClear> </a-input>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="orderNo" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'realAmount'">
          <span class="l-font-num l-bold"> {{ record.realAmount / 100 }} 元 </span>
        </template>

        <template v-if="column.dataIndex === 'status'">
          <a-tag color="warning" v-show="record.status == 1">未支付</a-tag>
          <a-tag color="success" v-show="record.status == 2">已支付</a-tag>
        </template>

        <template v-if="column.dataIndex === 'gainGoodsAmount'">
          <span class="l-font-success l-bold">+{{ record.gainGoodsAmount + record.presentedGoodsAmount }}</span>
          <div>({{ record.gainGoodsAmount }} +赠{{ record.presentedGoodsAmount }})</div>
        </template>

        <template v-if="column.dataIndex === 'mallGoodsType'">{{ getLabelByValue(mallGoodsTypes, record.mallGoodsType) }}</template>
        <template v-if="column.dataIndex === 'payType'">{{ getLabelByValue(ApGamePayTypes, record.payType) }}</template>
        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button auth="re_ap_game_pay_manual_notify_btn" type="primary" primary @click="handleNotify(record)"> <SendOutlined />手动回调 </l-auth-text-button>
        </template>
      </template>

      <template #summary>
        <a-table-summary fixed>
          <a-table-summary-row>
            <a-table-summary-cell>合计：</a-table-summary-cell>
            <a-table-summary-cell :col-span="14">
              <div v-if="totalLoading" class="l-font-num l-bold">加载中...</div>
              <div v-else>
                <span
                  >成功充值：<a-typography-text class="l-font-num l-bold"> {{ totalData.totalAmount / 100 }} </a-typography-text> 元</span
                >
                <span style="margin-left: 50px"
                  >成功订单数量：<a-typography-text class="l-font-num l-bold"> {{ totalData.totalCount }} </a-typography-text> 个</span
                >

                <span style="margin-left: 50px"
                  >成功人数：<a-typography-text class="l-font-num l-bold"> {{ totalData.totalUserCount }} </a-typography-text> 个</span
                >
              </div>
            </a-table-summary-cell>
          </a-table-summary-row>
        </a-table-summary>
      </template>
    </l-table>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { ApGamePayTypes, getLabelByValue, mallGoodsTypes, ApGamePayStatusList, ApGamePayStatus } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";
import { PaginationProps } from "ant-design-vue/es/pagination";
import { SendOutlined } from "@ant-design/icons-vue";

const columns = [
  {
    title: "序号",
    width: 70,
    dataIndex: "index",
    // fixed: "left",
  },

  {
    title: "充值金额",
    width: 100,
    dataIndex: "realAmount",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 100,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "支付状态",
    width: 90,
    dataIndex: "status",
    ellipsis: true,
  },

  {
    title: "订单号",
    width: 180,
    dataIndex: "orderNo",
    ellipsis: true,
  },

  {
    title: "外部订单号",
    width: 180,
    dataIndex: "outTradeNo",
    ellipsis: true,
  },

  {
    title: "物品类型",
    width: 100,
    dataIndex: "mallGoodsType",
    ellipsis: true,
  },

  {
    title: "获得物品",
    width: 160,
    dataIndex: "gainGoodsAmount",
    ellipsis: true,
  },

  {
    title: "支付方式",
    width: 100,
    dataIndex: "payType",
    ellipsis: true,
  },

  {
    title: "userId",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "备注",
    width: 120,
    dataIndex: "remark",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
  },

  {
    title: "回调时间",
    width: 180,
    dataIndex: "notifyTime",
  },

  {
    title: "操作",
    key: "action",
    dataIndex: "action",
    // fixed: "right",
    width: 100,
    align: "center",
  },
];

export default defineComponent({
  name: "re_ap_game_pay",
  components: {
    SendOutlined,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        orderNo: "",
        status: ApGamePayStatus.Success,
        maskId: undefined,
        userId: undefined,
        startTime: "",
        endTime: "",
        payType: undefined,
        mallGoodsType: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);
    const totalLoading = ref(true);
    const showManualNotify = ref(false);
    const manualNotifyOrderNo = ref("");
    const manualNotifyTableName = ref("");

    const currPagConfig = JSON.parse(JSON.stringify(pagConfig));
    const pagination = reactive(currPagConfig);

    const data = ref([]);

    //统计数据
    const totalData = reactive({
      totalAmount: 0,
      totalCount: 0,
      totalUserCount: 0,
    });

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("re_ap_game_pay/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("re_ap_game_pay", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //获取合计信息
    const getTotalData = async () => {
      try {
        totalLoading.value = true;
        const result = await http.get("re_ap_game_pay/list_statistics", {
          ...search.relData,
        });

        totalData.totalAmount = result.data.data.totalAmount;
        totalData.totalCount = result.data.data.totalCount;
        totalData.totalUserCount = result.data.data.totalUserCount;

        totalLoading.value = false;
      } catch (e) {
        totalLoading.value = false;
      }
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
      getTotalData();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    //显示手动回调
    const handleNotify = (record: any) => {
      manualNotifyTableName.value = record.tableName;
      manualNotifyOrderNo.value = record.orderNo;

      nextTick(() => {
        showManualNotify.value = true;
      });
    };

    const onNotifySuccess = () => {
      getList();
      getTotalData();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      mallGoodsTypes,
      ApGamePayTypes,
      ApGamePayStatusList,
      totalLoading,
      totalData,
      showManualNotify,
      manualNotifyOrderNo,
      manualNotifyTableName,
      handleTableChange,
      onSearch,
      getList,
      onReset,
      handleNotify,
      searchCol,
      disabledDate,
      getLabelByValue,
      getTotalData,
      onNotifySuccess,
    };
  },
});
</script>
